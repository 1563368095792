import $ from 'jquery';

// jQuery
$(document).on('turbolinks:load', function() {
  $("#guides_index").tablesorter({
    sortList: [[0,0], [1,0]],
    theme : "bootstrap",
    widthFixed: true,
    headers: {10: {sorter: false}},
    dateFormat : "ddmmyyyy"
  });
  $("#from_address").on('change', function(){
      $('#submit_email').prop('disabled', (this.value.trim().length===0));
  });

  $("body").on("click", "input[name='display_former']", function(ev) {
    $(".options_loading").text('loading...');
    return $(this).closest('form').submit();
  });

});
